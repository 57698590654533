<template>
	<div
		class="pg_setting_container"
		@keydown.escape="
			editingMonthNumber = null;
			editingTargetType = null;
		"
	>
		<header class="pg_header">
			<div class="pg_header_heading">
				<h2>Lidmaatschappen</h2>
			</div>
			<div class="pg_header_description">
				Hier kun je per vestiging een doel voor het aantal aanmeldingen en een maximum voor het
				aantal afmeldingen opgeven.
			</div>
		</header>
		<div class="pg_setting_controls_container a_padding-top-20">
			<div class="pg_branches">
				<select v-model="selectedVestigingId" class="v_transparent">
					<option
						v-for="(item, i) in sortedVestigingenByName"
						:key="`branch-option-${i}`"
						:value="item.id"
					>
						{{ item.naam }}
					</option>
				</select>
			</div>
			<div v-if="false" class="pg_period_buttons pg_year-selector">
				<button
					:disabled="year - 1 < presentYear"
					class="button v_transparent v_is-icon v_icon-size-20 a_margin-right-20 a_margin-bottom-0 a_no-padding-right a_no-padding-left"
					@click="year = year - 1"
				>
					<icon :icon-id="'icon_arrow-left'"></icon>
				</button>
				<!-- <div class="pg_period_current">{{ query.periode }} {{query.index + 1}}</div> -->
				<div class="pg_period_current">{{ year }}</div>
				<button
					class="button v_transparent v_is-icon v_icon-size-20 a_margin-left-20 a_margin-bottom-0 a_no-padding-right a_no-padding-left"
					@click="year = year + 1"
				>
					<icon :icon-id="'icon_arrow-right'"></icon>
				</button>
			</div>
		</div>
		<vue-scroll-table
			:data="membershipTargetsByLocation"
			:headers="tableData.headers"
			:class="{ 's_no-data': !membershipTargetsByLocation.length }"
			:classes="tableStylingClasses"
		>
			<template slot="aanmeldingen" slot-scope="{ data }">
				<div class="pg_monthly-target">
					<input
						v-if="
							editingMonthNumber === data.attributes.monthNumber &&
							editingTargetType === 'registrations'
						"
						v-model.number="data.value"
						name="amount"
						type="number"
						min="0"
						placeholder="120"
						@keyup.enter="onUpdateTarget('registrations', data.value, data.attributes.monthNumber)"
					/>
					<span v-else class="pg_montly-target_value a_padding-left-10">
						{{ data.value }}
					</span>
					<button
						v-if="
							editingMonthNumber === data.attributes.monthNumber &&
							editingTargetType === 'registrations'
						"
						class="button v_transparent v_is-icon v_icon-size-16 v_smaller a_fill_brand_secondary pg_button-edit"
						@click="onUpdateTarget('registrations', data.value, data.attributes.monthNumber)"
					>
						<icon icon-id="icon_check"></icon>
					</button>
					<button
						v-else
						class="button v_transparent v_is-icon v_icon-size-16 v_smaller a_fill_brand_secondary pg_button-edit"
						@click="onEditTarget('registrations', data.attributes.monthNumber)"
					>
						<icon icon-id="icon_edit2"></icon>
					</button>
				</div>
			</template>
			<template slot="afmeldingen" slot-scope="{ data }">
				<div class="pg_monthly-target">
					<input
						v-if="
							editingMonthNumber === data.attributes.monthNumber &&
							editingTargetType === 'cancellations'
						"
						v-model.number="data.value"
						name="amount"
						type="number"
						min="0"
						placeholder="120"
						@keyup.enter="onUpdateTarget('cancellations', data.value, data.attributes.monthNumber)"
					/>
					<span v-else class="pg_montly-target_value a_padding-left-10">
						{{ data.value }}
					</span>
					<button
						v-if="
							editingMonthNumber === data.attributes.monthNumber &&
							editingTargetType === 'cancellations'
						"
						class="button v_transparent v_is-icon v_icon-size-16 v_smallest a_fill_brand_secondary pg_button-edit"
						@click="onUpdateTarget('cancellations', data.value, data.attributes.monthNumber)"
					>
						<icon icon-id="icon_check"></icon>
					</button>
					<button
						v-else
						class="button v_transparent v_is-icon v_icon-size-16 v_smallest a_fill_brand_secondary pg_button-edit"
						@click="onEditTarget('cancellations', data.attributes.monthNumber)"
					>
						<icon icon-id="icon_edit2"></icon>
					</button>
				</div>
			</template>
		</vue-scroll-table>
		<div class="pg_membership-targets-total_container">
			<div class="pg_membership-targets-total_column a_padding-left-20 a_justify_flex-end">
				<span class="pg_membership-targets-total_text"> totaal: </span>
			</div>
			<div class="pg_membership-targets-total_column a_padding-left-20">
				<span class="pg_membership-targets-total_amount a_padding-left-10">
					{{ registrationTargetsSum }}
				</span>
				<span class="pg_membership-targets-total_text"> totaal: </span>
			</div>
			<div class="pg_membership-targets-total_column a_padding-left-20">
				<span class="pg_membership-targets-total_amount a_padding-left-10">
					{{ cancellationsTargetsSum }}
				</span>
			</div>
		</div>
	</div>
</template>
<script>
import moment from "moment";
import VueScrollTable from "vue-scroll-table";
import { mapActions, mapGetters, mapState } from "vuex";
import { stylingClasses } from "@/lib/vue-scroll-table-settings.js";
import { logger } from "@/logger";

export default {
	name: "SettingRegistrationTargets",
	components: {
		VueScrollTable,
	},
	data() {
		return {
			stuff: 1,
			selectedVestigingId: -1,
			presentYear: new Date().getFullYear(),
			year: new Date().getFullYear(),
			editingMonthNumber: null,
			editingTargetType: null,
			tableData: {
				headers: [
					{
						text: "Maand",
						width: 300,
					},
					{
						text: "Aanmeldingen",
						width: 300,
					},
					{
						text: "Afmeldingen",
						width: 300,
					},
				],
			},
			tableStylingClasses: stylingClasses,
		};
	},
	computed: {
		...mapGetters("vestiging", ["sortedVestigingenByName"]),
		...mapState("settings", ["registrationTargets", "cancellationTargets"]),
		membershipTargetsByLocation() {
			const filterOnLocationId = ({ vestigingId }) => {
				return vestigingId === this.selectedVestigingId;
			};

			const registrationTargetsByLocation = this.registrationTargets.filter(filterOnLocationId);
			const cancellationTargetsByLocation = this.cancellationTargets.filter(filterOnLocationId);

			if (!registrationTargetsByLocation.length || !cancellationTargetsByLocation.length) {
				return [];
			}

			const findByMonthIndex =
				(monthIndex) =>
				({ maand }) =>
					maand === monthIndex;

			const membershipTargetsByLocation = [];
			// The back-end uses a 1-based index for months.
			for (let currentMonth = 1; currentMonth < 13; currentMonth++) {
				membershipTargetsByLocation.push([
					// Moment uses a 0-based index for months.
					moment.months(currentMonth - 1),
					// Registrationtargets are in the second column
					{
						value: registrationTargetsByLocation.find(findByMonthIndex(currentMonth)).waarde,
						attributes: {
							monthNumber: currentMonth,
						},
					},
					// Cancellation 'targets' are in the second column
					{
						value: cancellationTargetsByLocation.find(findByMonthIndex(currentMonth)).waarde,
						attributes: {
							monthNumber: currentMonth,
						},
					},
				]);
			}

			return membershipTargetsByLocation;
		},
		registrationTargetsSum() {
			return this.membershipTargetsByLocation.reduce((sum, [, { value }]) => {
				return sum + value;
			}, 0);
		},
		cancellationsTargetsSum() {
			return this.membershipTargetsByLocation.reduce((sum, [, , { value }]) => {
				return sum + value;
			}, 0);
		},
	},
	watch: {
		year: {
			immediate: true,
			async handler(year) {
				try {
					await this.getRegistrationTargetsByYear({
						year,
					});
					await this.getCancellationTargetsByYear({
						year,
					});
				} catch (error) {
					logger.error("Error while getting registration targets by year", error);
				}
			},
		},
	},
	async mounted() {
		if (this.sortedVestigingenByName.length === 0) {
			try {
				await this.getVestigingen();
			} catch (error) {
				logger.error("Error while getting branches", error);
			}
		}
		// If locations have been loaded, select the first location by default in the dropdown.
		this.selectedVestigingId = this.sortedVestigingenByName[0]
			? this.sortedVestigingenByName[0].id
			: -1;
	},
	methods: {
		...mapActions("modal", ["closeModal", "openModal"]),
		...mapActions("settings", [
			"getCancellationTargetsByYear",
			"getRegistrationTargetsByYear",
			"updateRegistrationTargets",
			"updateCancellationTargets",
		]),
		...mapActions("vestiging", ["getVestigingen"]),
		onEditTarget(targetType, monthNumber) {
			this.editingTargetType = targetType;
			this.editingMonthNumber = monthNumber;
		},
		async onUpdateTarget(targetType, newValue, monthNumber) {
			if (!["registrations", "cancellations"].includes(targetType)) {
				logger.warn(`Unknown targetType: ${targetType}`);
				return;
			}

			this.openModal({
				name: "confirm",
				data: {
					callback: async () => {
						const payload = [
							{
								vestigingId: this.selectedVestigingId,
								jaar: this.year,
								waarde: newValue,
								maand: monthNumber,
							},
						];

						if (targetType === "registrations") {
							await this.updateRegistrationTargets(payload);
						} else if (targetType === "cancellations") {
							await this.updateCancellationTargets(payload);
						}

						this.openModal({
							name: "confirm",
							data: {
								callback: async () => {
									if (targetType === "registrations") {
										await this.updateRegistrationTargets(payload);
									} else if (targetType === "cancellations") {
										await this.updateCancellationTargets(payload);
									}
									this.editingMonthNumber = null;
									this.closeModal();
								},
							},
						});
					},
				},
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.pg_setting_container {
	::v-deep .vst_pagination-container {
		display: none;
	}
}

.pg_year-selector {
	flex: 0 1 50%;
	min-width: initial;
	padding: 0 1.25rem;
}

.pg_table {
	input {
		margin: 0 1rem 0 0;
		flex: 0 1 auto;
		width: 50%;
		padding-left: 10px;
	}
}

::v-deep .pg_table {
	th {
		width: 33% !important;
	}
}

.pg_monthly-target {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.pg_montly-target_value {
	flex: 0 1 auto;
}

.pg_button-edit {
	margin: 0;
}

.pg_membership-targets {
	&-total {
		&_container {
			display: flex;
			justify-content: space-between;
			padding: 1rem 0;
		}
		&_column {
			display: flex;
			justify-content: space-between;
			width: 100%;
			padding: 0;
		}
		&_text {
			color: $brand-primary-lighter;
		}
		&_amount {
		}
	}
}
</style>
