var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pg_setting_container",on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"escape",undefined,$event.key,undefined)){ return null; }_vm.editingMonthNumber = null;
		_vm.editingTargetType = null;}}},[_vm._m(0),_c('div',{staticClass:"pg_setting_controls_container a_padding-top-20"},[_c('div',{staticClass:"pg_branches"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedVestigingId),expression:"selectedVestigingId"}],staticClass:"v_transparent",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedVestigingId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.sortedVestigingenByName),function(item,i){return _c('option',{key:("branch-option-" + i),domProps:{"value":item.id}},[_vm._v(" "+_vm._s(item.naam)+" ")])}),0)]),(false)?_c('div',{staticClass:"pg_period_buttons pg_year-selector"},[_c('button',{staticClass:"button v_transparent v_is-icon v_icon-size-20 a_margin-right-20 a_margin-bottom-0 a_no-padding-right a_no-padding-left",attrs:{"disabled":_vm.year - 1 < _vm.presentYear},on:{"click":function($event){_vm.year = _vm.year - 1}}},[_c('icon',{attrs:{"icon-id":'icon_arrow-left'}})],1),_c('div',{staticClass:"pg_period_current"},[_vm._v(_vm._s(_vm.year))]),_c('button',{staticClass:"button v_transparent v_is-icon v_icon-size-20 a_margin-left-20 a_margin-bottom-0 a_no-padding-right a_no-padding-left",on:{"click":function($event){_vm.year = _vm.year + 1}}},[_c('icon',{attrs:{"icon-id":'icon_arrow-right'}})],1)]):_vm._e()]),_c('vue-scroll-table',{class:{ 's_no-data': !_vm.membershipTargetsByLocation.length },attrs:{"data":_vm.membershipTargetsByLocation,"headers":_vm.tableData.headers,"classes":_vm.tableStylingClasses},scopedSlots:_vm._u([{key:"aanmeldingen",fn:function(ref){
		var data = ref.data;
return [_c('div',{staticClass:"pg_monthly-target"},[(
						_vm.editingMonthNumber === data.attributes.monthNumber &&
						_vm.editingTargetType === 'registrations'
					)?_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(data.value),expression:"data.value",modifiers:{"number":true}}],attrs:{"name":"amount","type":"number","min":"0","placeholder":"120"},domProps:{"value":(data.value)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onUpdateTarget('registrations', data.value, data.attributes.monthNumber)},"input":function($event){if($event.target.composing){ return; }_vm.$set(data, "value", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}):_c('span',{staticClass:"pg_montly-target_value a_padding-left-10"},[_vm._v(" "+_vm._s(data.value)+" ")]),(
						_vm.editingMonthNumber === data.attributes.monthNumber &&
						_vm.editingTargetType === 'registrations'
					)?_c('button',{staticClass:"button v_transparent v_is-icon v_icon-size-16 v_smaller a_fill_brand_secondary pg_button-edit",on:{"click":function($event){return _vm.onUpdateTarget('registrations', data.value, data.attributes.monthNumber)}}},[_c('icon',{attrs:{"icon-id":"icon_check"}})],1):_c('button',{staticClass:"button v_transparent v_is-icon v_icon-size-16 v_smaller a_fill_brand_secondary pg_button-edit",on:{"click":function($event){return _vm.onEditTarget('registrations', data.attributes.monthNumber)}}},[_c('icon',{attrs:{"icon-id":"icon_edit2"}})],1)])]}},{key:"afmeldingen",fn:function(ref){
					var data = ref.data;
return [_c('div',{staticClass:"pg_monthly-target"},[(
						_vm.editingMonthNumber === data.attributes.monthNumber &&
						_vm.editingTargetType === 'cancellations'
					)?_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(data.value),expression:"data.value",modifiers:{"number":true}}],attrs:{"name":"amount","type":"number","min":"0","placeholder":"120"},domProps:{"value":(data.value)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onUpdateTarget('cancellations', data.value, data.attributes.monthNumber)},"input":function($event){if($event.target.composing){ return; }_vm.$set(data, "value", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}):_c('span',{staticClass:"pg_montly-target_value a_padding-left-10"},[_vm._v(" "+_vm._s(data.value)+" ")]),(
						_vm.editingMonthNumber === data.attributes.monthNumber &&
						_vm.editingTargetType === 'cancellations'
					)?_c('button',{staticClass:"button v_transparent v_is-icon v_icon-size-16 v_smallest a_fill_brand_secondary pg_button-edit",on:{"click":function($event){return _vm.onUpdateTarget('cancellations', data.value, data.attributes.monthNumber)}}},[_c('icon',{attrs:{"icon-id":"icon_check"}})],1):_c('button',{staticClass:"button v_transparent v_is-icon v_icon-size-16 v_smallest a_fill_brand_secondary pg_button-edit",on:{"click":function($event){return _vm.onEditTarget('cancellations', data.attributes.monthNumber)}}},[_c('icon',{attrs:{"icon-id":"icon_edit2"}})],1)])]}}])}),_c('div',{staticClass:"pg_membership-targets-total_container"},[_vm._m(1),_c('div',{staticClass:"pg_membership-targets-total_column a_padding-left-20"},[_c('span',{staticClass:"pg_membership-targets-total_amount a_padding-left-10"},[_vm._v(" "+_vm._s(_vm.registrationTargetsSum)+" ")]),_c('span',{staticClass:"pg_membership-targets-total_text"},[_vm._v(" totaal: ")])]),_c('div',{staticClass:"pg_membership-targets-total_column a_padding-left-20"},[_c('span',{staticClass:"pg_membership-targets-total_amount a_padding-left-10"},[_vm._v(" "+_vm._s(_vm.cancellationsTargetsSum)+" ")])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"pg_header"},[_c('div',{staticClass:"pg_header_heading"},[_c('h2',[_vm._v("Lidmaatschappen")])]),_c('div',{staticClass:"pg_header_description"},[_vm._v(" Hier kun je per vestiging een doel voor het aantal aanmeldingen en een maximum voor het aantal afmeldingen opgeven. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pg_membership-targets-total_column a_padding-left-20 a_justify_flex-end"},[_c('span',{staticClass:"pg_membership-targets-total_text"},[_vm._v(" totaal: ")])])}]

export { render, staticRenderFns }